import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo";
import 'bulma/css/bulma.css';
import '@fortawesome/fontawesome-free/css/all.css'
import MainContent from '../components/MainContent/MainContent';

const IndexPage = () => (
  <Layout>
    <SEO title="Especialistas en Seguridad" />
    <MainContent/>
  </Layout>
)

export default IndexPage
