import React from 'react';
import { Link } from "gatsby"
import { Row, Col } from 'react-flexbox-grid';
import { Carousel } from 'react-responsive-carousel';
import oficinaImage from '../../images/services/oficinas2.jpg';
import retailImage from '../../images/services/retail.jpg';
import bodegaImage from '../../images/services/bodegas3.jpg';
import domicilioImage from '../../images/services/domicilios2.jpg';
import cercoElectricoImage from '../../images/services/cerco_electrico.jpg';
import alarmaImage from '../../images/services/alarmas.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './MainContent.css';

const MainContent = () => {

   return(
      <>
         <section id="carousel" style={{ position: 'relative' }}>
            <Row>
               <Col xs={12} style={{padding: 0}}>
                  <Carousel
                     showArrows={false}
                     showStatus={false}
                     showThumbs={false}
                     infiniteLoop={true} 
                     interval={5000}
                     autoPlay>
                     <div style={{height: '100%'}}>
                        <img 
                           alt="black-camera"
                           style={{width: '100%', height: '100%'}}
                           src={require('../../images/backgrounds/black-camera.jpg')}  />
                     </div>
                     <div style={{height: '100%'}}>
                        <img 
                           alt="black-lamp-camera"
                           style={{width: '100%', height: '100%', opacity: 0.4}}
                           src={require('../../images/backgrounds/black-lamp-post-with-mounted-cameras-1135453.jpg')}  />
                     </div>
                     <div style={{height: '100%', opacity: 0.7}}>
                        <img 
                           alt="white-security"
                           style={{width: '100%', height: '100%'}}
                           src={require('../../images/backgrounds/white-security-camera-776516.jpg')}  />
                     </div>
                     <div style={{height: '100%', opacity: 0.7}}>
                        <img
                           alt="city-life" 
                           style={{width: '100%', height: '100%'}}
                           src={require('../../images/backgrounds/city-life.jpg')}  />
                     </div>                    
                  </Carousel>
               </Col>
            </Row>
            <div 
               className="has-text-centered-tablet"
               style={{ position: 'absolute', width: '80%', height: '70%', top: 0, left: 0, right: 0, bottom: 0, margin: 'auto', zIndex: 10}}>
               <h1 style={{lineHeight: '1,1em', marginTop: 60,  fontWeight: 700}} className="has-text-white is-uppercase  is-size-3-mobile is-size-2-tablet is-size-1-desktop">
                  Somos Expertos en Seguridad
               </h1>
               <h2 style={{lineHeight: '1,1em', marginTop: 40}} className="has-text-white is-size-5-mobile is-size-4-tablet is-size-3-desktop">
                  Entregamos las mejores soluciones en seguridad, según las necesidades de tu hogar o negocio
               </h2>
            </div>
         </section>
         <section id="services">
            <Row>
               <Col xs={12} style={{ backgroundColor: '#fff', height: 90, display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '2px solid #f5f5f5', paddingBottom: 20, paddingTop: 20 }}>
                  <h3 style={{fontWeight: 500}} className="has-text-black is-size-3-mobile is-size-3-tablet is-size-2-desktop">Nuestros Servicios</h3>
               </Col>
               <Col xs={12} sm={12} md={6} lg={6} className="blend-mode-image" style={{backgroundImage: `url(${domicilioImage})`}}>
                  <div className="cctv-image-wrap">
                     <img 
                        className="cctv-image" 
                        alt="cctv-1" 
                        src={require('../../images/cctv/cctv-1.png')}  />
                  </div>
                  <div className="cctv-text-wrap">
                     <Col xs={8} md={12}>
                        <h2 className="cctv-text is-size-2-desktop is-size-3-tablet is-size-3-mobile">
                           Instalaciones 
                           <strong style={{fontWeight: 700, color: '#fff'}}> Domiciliarias</strong>
                        </h2>
                     </Col>
                  </div>
                  <div className="contact-button-wrapper">
                     <Link className="contact-button" to="/contact">
                        Solicitar
                     </Link>
                  </div>
               </Col>
               <Col xs={12} sm={12} md={6} lg={6} className="blend-mode-image" style={{backgroundImage: `url(${oficinaImage})`}}>
                  <div className="cctv-image-wrap">
                     <img 
                        className="cctv-image" 
                        alt="cctv-1" 
                        src={require('../../images/cctv/cctv-5.png')}  />
                  </div>
                  <div className="cctv-text-wrap">
                     <Col xs={8} md={12}>
                        <h2 className="cctv-text is-size-2-desktop is-size-3-tablet is-size-3-mobile">
                           Instalaciones 
                           <strong style={{fontWeight: 700, color: '#fff'}}> Oficinas</strong>
                        </h2>
                     </Col>
                  </div>
                  <div className="contact-button-wrapper">
                     <Link className="contact-button" to="/contact">
                        Solicitar
                     </Link>
                  </div>
               </Col>
               <Col xs={12} sm={12} md={6} lg={6} className="blend-mode-image" style={{backgroundImage: `url(${retailImage})`}}>
                  <div className="cctv-image-wrap">
                     <img 
                        className="cctv-image" 
                        alt="cctv-1" 
                        style={{width: 'auto', position: 'relative', left: 50}}
                        src={require('../../images/cctv/cctv-8.png')}  />
                  </div>
                  <div className="cctv-text-wrap">
                     <Col xs={7} md={12} lg={12}>
                        <h2 className="cctv-text is-size-2-desktop is-size-3-tablet is-size-3-mobile">
                           Instalaciones 
                           <strong style={{fontWeight: 700, color: '#fff'}}> Retail</strong>
                        </h2>
                     </Col>
                  </div>
                  <div className="contact-button-wrapper">
                     <Link className="contact-button" to="/contact">
                        Solicitar
                     </Link>
                  </div>
               </Col>
               <Col xs={12} sm={12} md={6} lg={6} className="blend-mode-image" style={{backgroundImage: `url(${bodegaImage})`}}>
                  <div className="cctv-image-wrap" style={{height: 196}}>
                     <img 
                        className="cctv-image" 
                        alt="cctv-1" 
                        style={{width: 'auto', position: 'relative', float: 'right'}}
                        src={require('../../images/cctv/cctv-4.png')}  />
                  </div>
                  <div className="cctv-text-wrap">
                     <Col xs={8} md={12}>
                        <h2 className="cctv-text is-size-2-desktop is-size-3-tablet is-size-3-mobile">
                           Instalaciones 
                           <strong style={{fontWeight: 700, color: '#fff'}}> Bodegas</strong>
                        </h2>
                     </Col>
                  </div>
                  <div className="contact-button-wrapper">
                     <Link className="contact-button" to="/contact">
                        Solicitar
                     </Link>
                  </div>
               </Col>
               <Col id="alarms" xs={12} sm={12} md={6} lg={6} className="blend-mode-image" style={{backgroundImage: `url(${alarmaImage})`,  display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                  <div className="cctv-text-wrap">
                     <Col xs={10} sm={12} md={12}>
                        <h2 className="cctv-text is-size-2-desktop is-size-3-tablet is-size-3-mobile">
                           Instalaciones 
                           <strong style={{fontWeight: 700, color: '#fff'}}> Alarmas y Sirenas</strong>
                        </h2>
                     </Col>
                  </div>
                  <div className="contact-button-wrapper">
                     <Link className="contact-button" to="/contact">
                        Solicitar
                     </Link>
                  </div>
               </Col>
               <Col id="electric-fence" xs={12} sm={12} md={6} lg={6} className="blend-mode-image" style={{backgroundImage: `url(${cercoElectricoImage})`, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                  <div className="cctv-text-wrap">
                     <Col xs={9} sm={12} md={11} lg={12}>
                        <h2 className="cctv-text is-size-2-desktop is-size-3-tablet is-size-3-mobile">
                           Instalaciones 
                           <strong style={{fontWeight: 700, color: '#fff'}}> Cerco Eléctrico</strong>
                        </h2>
                     </Col>
                  </div>
                  <div className="contact-button-wrapper">
                     <Link className="contact-button" to="/contact">
                        Solicitar
                     </Link>
                  </div>
               </Col>
            </Row>
         </section>
      </>
   )
}

export default MainContent;
